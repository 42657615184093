<template>
  <div>
    <template>
      <!-- First Row -->
      <b-row class="match-height mt-2">
        <b-col md="8">
          <flow-view-flow-info-card />
        </b-col>
        <b-col md="4">
          <flow-jobs-list />
        </b-col>
        <b-col md="12">
          <flow-contacts />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { BRow, BCol } from "bootstrap-vue";
import FlowViewFlowInfoCard from "./FlowViewFlowInfoCard.vue";
import FlowJobsList from "./FlowJobsList.vue";
import flowStoreModule from "../flowStoreModule";
import store from "@/store";
import FlowContacts from "./FlowContacts.vue";

export default {
  components: {
    BRow,
    BCol,

    // Local Components
    FlowViewFlowInfoCard,
    FlowJobsList,
    FlowContacts,
  },
  mixins: [PageBoxed],
  directives: {
    Ripple,
  },
  setup() {
    const FLOW_APP_STORE_MODULE_NAME = "app-flow";

    // Register module
    if (!store.hasModule(FLOW_APP_STORE_MODULE_NAME))
      store.registerModule(FLOW_APP_STORE_MODULE_NAME, flowStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FLOW_APP_STORE_MODULE_NAME))
        store.unregisterModule(FLOW_APP_STORE_MODULE_NAME);
    });
  },
};
</script>
