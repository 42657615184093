var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-transaction",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v("Jobs")])],1),_c('b-card-body',[(_vm.flowJobs && _vm.flowJobs.length === 0)?_c('b-alert',{staticClass:"mx-1",attrs:{"variant":"primary","show":true}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Aucun job pour ce flux")])]):_vm._e(),_vm._l((_vm.flowJobs),function(flowJob){return _c('div',{key:flowJob.moment,staticClass:"transaction-item mx-2"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"rounded":"","size":"42","variant":("" + (flowJob.message === 'success' ? 'light-success' : 'light-danger')),"title":flowJob.message !== 'success'
                ? flowJob.error.type +
                  '-' +
                  flowJob.error.origin +
                  '-' +
                  flowJob.error.message
                : 'Job OK'}},[_c('feather-icon',{attrs:{"size":"18","icon":("" + (flowJob.message === 'success'
                  ? 'CheckIcon'
                  : 'AlertTriangleIcon'))}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"transaction-title"},[_vm._v(" "+_vm._s(_vm.formatMoment(flowJob.moment))+" ")]),_c('small',[_vm._v("Context: "+_vm._s(flowJob.context)+" - Durée: "+_vm._s(flowJob.duration / 1000)+" secondes")])])],1),_c('div',{staticClass:"font-weight-bolder",class:flowJob.message === 'success' ? 'text-success' : 'text-danger'},[_vm._v(" "+_vm._s(flowJob.message)+" ")])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }