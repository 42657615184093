<template>
  <div>
    <modal-create-contact :params="params" @update-list="refetchData" />

    <modal-edit-contact :params="params" @update-list="refetchData" />

    <!-- Table Container Card -->
    <b-card no-body>
      <b-row class="match-height my-1 ml-2">
        <b-col cols="2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-modal.modal-create-contact
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>Créer Contact</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="refContactListTable"
        :items="findContactsByFlowID"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Column: Client -->
        <template #cell(contact)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="
                  resolveAvatarLink(data.item.first_name, data.item.last_name)
                "
                :text="avatarText(data.item.first_name)"
              />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Emploi -->
        <template #cell(poste)="data">
          <span class="text-nowrap">
            {{ data.item.job }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-b-modal.modal-edit-contact
            @click="selectContactID(data.item.ID)"
          >
            <feather-icon icon="EditIcon" size="12" />
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ dataMeta.from }} à {{ dataMeta.to }} pour
              {{ dataMeta.of }} contacts</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContacts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import { resolveAvatarLink } from "@core/utils/utils";
import { useRouter } from "@core/utils/utils";
import useContacts from "./useContacts";

// modals
import ModalCreateContact from "./modal-contacts/ModalCreateContact.vue";
import ModalEditContact from "./modal-contacts/ModalEditContact.vue";

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,

    ModalCreateContact,
    ModalEditContact,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      params,
      findContactsByFlowID,
      tableColumns,
      perPage,
      currentPage,
      totalContacts,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refContactListTable,

      refetchData,
    } = useContacts();

    // Pull customerID from router
    const { route } = useRouter();

    params.value.selectedFlowID = route.value.params.id;

    return {
      params,
      findContactsByFlowID,
      tableColumns,
      perPage,
      currentPage,
      totalContacts,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refContactListTable,

      refetchData,

      avatarText,
      resolveAvatarLink,
    };
  },
  methods: {
    selectContactID(contactID) {
      this.params.selectedContactID = contactID;
    },
  },
};
</script>

<style lang="scss"></style>
