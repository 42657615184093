var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.flow)?_c('b-card',[_c('validation-observer',{ref:"flowRules",attrs:{"tag":"form"}},[_c('b-row',{staticClass:"ml-2 mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-avatar',{attrs:{"src":"" + _vm.publicPath + "images/flux/" + "" + (_vm.flow.logo),"size":"104px","rounded":""}})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.flow.name)+" ")])]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Logo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Logo","label-for":"Logo","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"logo","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.fluxImageOptions},model:{value:(_vm.flow.logo),callback:function ($$v) {_vm.$set(_vm.flow, "logo", $$v)},expression:"flow.logo"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,4024147970)})],1)],1)],1)],1)],1),_c('b-row',{staticClass:"mx-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Nom","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"Nom","vid":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Universal Flow"},model:{value:(_vm.flow.name),callback:function ($$v) {_vm.$set(_vm.flow, "name", $$v)},expression:"flow.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3363671947)})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","vid":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false : null,"placeholder":"Description de l'entreprise"},model:{value:(_vm.flow.description),callback:function ($$v) {_vm.$set(_vm.flow, "description", $$v)},expression:"flow.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3530558057)})],1),_c('b-form-group',{attrs:{"label":"Origine du flux","label-for":"Origine du flux"}},[_c('validation-provider',{attrs:{"name":"Origine du flux","vid":"Origine du flux","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"flow_origin","state":errors.length > 0 ? false : null,"placeholder":"flow_origin"},model:{value:(_vm.flow.flow_origin),callback:function ($$v) {_vm.$set(_vm.flow, "flow_origin", $$v)},expression:"flow.flow_origin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1812545549)})],1),_c('b-form-group',{attrs:{"label":"Nom du job (talend)","label-for":"Nom du job"}},[_c('validation-provider',{attrs:{"name":"Nom du job","vid":"Nom du job","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"job_name","state":errors.length > 0 ? false : null,"placeholder":"job_name"},model:{value:(_vm.flow.job_name),callback:function ($$v) {_vm.$set(_vm.flow, "job_name", $$v)},expression:"flow.job_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,990328043)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Type Offres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Type Offres","label-for":"Type Offres","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"type_offers","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOffersOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"reduce":function (val) { return val.value; },"label":"text"},model:{value:(_vm.flow.type_offers),callback:function ($$v) {_vm.$set(_vm.flow, "type_offers", $$v)},expression:"flow.type_offers"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1623444869)}),_c('validation-provider',{attrs:{"name":"Format","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Format","label-for":"Format","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"format","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.formatOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"reduce":function (val) { return val.value; },"label":"text"},model:{value:(_vm.flow.format),callback:function ($$v) {_vm.$set(_vm.flow, "format", $$v)},expression:"flow.format"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,895037139)}),_c('validation-provider',{attrs:{"name":"Statut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Statut","label-for":"Statut","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"status","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"selectable":function (option) { return !option.value.includes('select_value'); },"reduce":function (val) { return val.value; },"label":"text"},model:{value:(_vm.flow.status),callback:function ($$v) {_vm.$set(_vm.flow, "status", $$v)},expression:"flow.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,4273385252)})],1)],1)],1),_c('b-row',{attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"block":"","variant":"primary"},on:{"click":_vm.validationForm}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_vm._v(" Editer ")],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }