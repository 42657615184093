























































































































































































































import Vue from "vue";

// Typescript Files
import * as Api from "@/axios-generated/backoffice/index";

import useFlowsDetail from "./useFlowsDetail";
import { useRouter } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { required } from "@validations";

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      publicPath: process.env.BASE_URL,
      typeOffersOptions: [
        { value: "select_value", text: "Select Value" },
        {
          value: Api.FlowTypeOffersEnum.Neuf,
          text: Api.FlowTypeOffersEnum.Neuf,
        },
        {
          value: Api.FlowTypeOffersEnum.Ancien,
          text: Api.FlowTypeOffersEnum.Ancien,
        },
      ],
      formatOptions: [
        { value: "select_value", text: "Select Value" },
        {
          value: Api.FlowFormatEnum.PolirisCsv,
          text: Api.FlowFormatEnum.PolirisCsv,
        },
        { value: Api.FlowFormatEnum.ApiXml, text: Api.FlowFormatEnum.ApiXml },
        { value: Api.FlowFormatEnum.ApiJson, text: Api.FlowFormatEnum.ApiJson },
      ],
      statusOptions: [
        { value: "select_value", text: "Select Value" },
        { value: Api.FlowStatusEnum.Created, text: Api.FlowStatusEnum.Created },
        { value: Api.FlowStatusEnum.Enabled, text: Api.FlowStatusEnum.Enabled },
        {
          value: Api.FlowStatusEnum.Suspended,
          text: Api.FlowStatusEnum.Suspended,
        },
        {
          value: Api.FlowStatusEnum.Disabled,
          text: Api.FlowStatusEnum.Disabled,
        },
      ],
    };
  },
  setup() {
    const { flow, fetchFlowByID, updateFlow } = useFlowsDetail();
    const { route } = useRouter();

    fetchFlowByID(route.value.params.id);

    const fluxImageOptions = require
      .context("/public/images/flux", true, /^.*\.jpg$/)
      .keys()
      .map((val) => val.replace("./", ""));

    return {
      flow,
      updateFlow,
      fluxImageOptions,
    };
  },
  methods: {
    submitForm() {
      this.updateFlow();
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.flowRules.validate().then((success) => {
          if (success) {
            this.submitForm();
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
});
