<template>
  <b-card class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>Jobs</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-alert
        class="mx-1"
        variant="primary"
        :show="true"
        v-if="flowJobs && flowJobs.length === 0"
      >
        <h4 class="alert-heading">Aucun job pour ce flux</h4>
      </b-alert>
      <div
        v-for="flowJob in flowJobs"
        :key="flowJob.moment"
        class="transaction-item mx-2"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="`${
                flowJob.message === 'success' ? 'light-success' : 'light-danger'
              }`"
              v-b-tooltip.hover.v-primary
              :title="
                flowJob.message !== 'success'
                  ? flowJob.error.type +
                    '-' +
                    flowJob.error.origin +
                    '-' +
                    flowJob.error.message
                  : 'Job OK'
              "
            >
              <feather-icon
                size="18"
                :icon="`${
                  flowJob.message === 'success'
                    ? 'CheckIcon'
                    : 'AlertTriangleIcon'
                }`"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ formatMoment(flowJob.moment) }}
            </h6>
            <small
              >Context: {{ flowJob.context }} - Durée:
              {{ flowJob.duration / 1000 }} secondes</small
            >
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="
            flowJob.message === 'success' ? 'text-success' : 'text-danger'
          "
        >
          {{ flowJob.message }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useFlowsDetail from "./useFlowsDetail";
import { useRouter } from "@core/utils/utils";
// Custom formater
import { formatMoment } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BAlert,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      subscriptions: null,
    };
  },
  setup() {
    const { flowJobs, findJobsByFlowJobName } = useFlowsDetail();
    const { route } = useRouter();

    findJobsByFlowJobName(route.value.query.flowCode);

    return {
      flowJobs,
    };
  },
  methods: {
    formatMoment,
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/variables";

.knowledge-base-bg {
  background-color: rgba($primary, 0.2) !important;
}

.card-body {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
