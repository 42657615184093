import store from "@/store";
import { ref } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useFlowsDetail() {
  // Use toast
  const toast = useToast();

  const flow = ref({});
  const flowJobs = ref({});
  const perPage = ref(6);
  const currentPage = ref(1);

  const fetchFlowByID = (flowID) => {
    store
      .dispatch("app-flow/fetchFlowByID", { flowID })
      .then((response) => {
        flow.value = response.data;
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Impossible de récupérer le flux",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  const findJobsByFlowJobName = (code) => {
    store
      .dispatch("app-flow/findJobsByFlowJobName", {
        code,
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then((response) => {
        flowJobs.value = response.data.flow_jobs;
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Impossible de récupérer la liste des flux",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  const updateFlow = () => {
    store
      .dispatch("app-flow/editFlow", { editFlow: flow.value })
      .then(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Flux mis à jour",
              icon: "EditIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Impossible de mettre à jour le flux",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    flow,
    flowJobs,

    updateFlow,
    fetchFlowByID,
    findJobsByFlowJobName,
  };
}
