import { ref, watch, computed } from "@vue/composition-api";
// @ts-ignore
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
// @ts-ignore
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useContacts() {
  // Use toast
  const toast = useToast();

  const customer = ref({});

  const refContactListTable = ref(null);

  const params = ref({
    selectedFlowID: "",
    selectedContactID: "",
  });

  // Table Handlers
  const tableColumns = [
    { key: "contact", sortable: true },
    { key: "poste", sortable: false },
    { key: "email", sortable: false },
    { key: "mobile", sortable: false },
    { key: "actions", sortable: false },
  ];
  const perPage = ref(6);
  const totalContacts = ref(0);
  const currentPage = ref(1);
  const sortBy = ref("uuid");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refContactListTable.value
      ? refContactListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContacts.value,
    };
  });

  const refetchData = () => {
    refContactListTable.value.refresh();
  };

  const filterSortBy = (sortByParam) => {
    switch (sortByParam) {
      case "utilisateur":
        return "first_name";
      case "emploi":
        return "job_role";
      case "role":
        return "role";
      case "statut":
        return "status";
      default:
        return "uuid";
    }
  };

  watch([currentPage, perPage], () => {
    refetchData();
  });

  const findContactsByFlowID = (ctx, callback) => {
    const sortByFiltered = filterSortBy(sortBy.value);
    store
      .dispatch("app-flow/findContactsByFlowID", {
        flowID: params.value.selectedFlowID,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortByFiltered,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const contacts = response.data.contacts;
        totalContacts.value = response.data.count;
        callback(contacts);
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const createContact = (newContact) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-flow/createContact", { newContact })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Création validée",
              icon: "SuccessIcon",
              variant: "success",
            },
          });
          resolve(response);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur durant la création",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  const editContact = (updateContact) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-flow/editContact", { updateContact })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Contact modifié",
              icon: "SuccessIcon",
              variant: "success",
            },
          });
          resolve(response);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur durant la modification",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  const findContactByID = (contactID) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-flow/findContactByID", { contactID })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur contact introuvable",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  const deleteContactByID = (contactID) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch("app-flow/deleteContactByID", { contactID })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Erreur contact introuvable",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          reject(error);
        });
    });
  };

  return {
    params,
    findContactsByFlowID,
    tableColumns,
    perPage,
    currentPage,
    totalContacts,
    dataMeta,
    sortBy,
    isSortDirDesc,
    refContactListTable,

    customer,

    refetchData,

    createContact,
    editContact,
    findContactByID,
    deleteContactByID,
  };
}
